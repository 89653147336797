import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { IOfferBaseAvailability } from '@ess/types';

import { getBookingModeDate } from '@ess/utils';

import Box from '@ess/ui/Box';
import Text from '@ess/ui/Text';
import Alert, { SizeEnum } from '@ess/ui/Alert';
import FlexBox from '@ess/ui/FlexBox';

type BookingModeInfoProps = {
  availability?: IOfferBaseAvailability;
  style?: CSSProperties;
  size?: SizeEnum;
}

const bookingModeLabel: Record<string, string> = {
  option: 'lbl_possible_option',
  quota: 'lbl_possible_quota',
};

const styles = {
  display: 'grid',
  gridTemplateColumns: 'minmax(150px, 200px) auto',
};

const BookingModeInfo = ({ availability = undefined, style = {}, size = 'small' }: BookingModeInfoProps) => {
  const { t } = useTranslation();
  const bookingOption = getBookingModeDate(availability, 'option');
  const bookingQuota = getBookingModeDate(availability, 'quota');

  const bookingModes: Record<string, string | boolean> = {
    option: bookingOption,
    quota: bookingQuota,
  };

  const modes = Object.keys(bookingModes).filter((item) => bookingModes[item]);
  const hasMoreThanOne = modes.length > 1;

  if (!modes.length) {
    return null;
  }

  return (
    <Alert
      severity="info"
      showBorder={false}
      size={size}
      styles={{
        justifyContent: !hasMoreThanOne ? 'center' : 'flex-start',
        borderRadius: '0px',
        width: '100%',
        ...style,
      }}
    >
      <FlexBox width="100%" flexDirection="column">
        {modes.map((mode) => (
          <Box display="inline" style={hasMoreThanOne ? styles : { textAlign: 'center' }}>
            <Text as="span" lineHeight="18px" fontSize="12px" mr="2px">{t(bookingModeLabel[mode])}</Text>
            <Text as="span" lineHeight="18px" fontSize="12px" fontWeight="bold">{bookingModes[mode]}</Text>
          </Box>
        ))}
      </FlexBox>
    </Alert>
  );
};

export { BookingModeInfo };
