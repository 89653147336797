import React, { useContext } from 'react';
import { has, includes } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IDictionary } from '@ess/types';

import { getAttributeIcon } from '@ess/utils';

import { AppConfigContext } from '@ess/context/AppConfigContext';

import { HotelAttribute } from './HotelAttribute';

import { Styled } from './HotelAttribute.styles';

type DescriptionMode = 'tooltip' | 'label';

export type HotelAttributesGroupProps = {
  attributes: string[]
  descriptionMode?: DescriptionMode
  selectedAttributes?: string[]
};
export const hotelAttributesTooltipDescription = (item: string, dictionary: IDictionary<{ text: string }>) => (
  has(dictionary, item) ? dictionary[item].text : item
);

const defaultProps = {
  descriptionMode: 'tooltip' as DescriptionMode,
  selectedAttributes: [],
};

const HotelAttributesGroup = ({
  attributes,
  descriptionMode,
  selectedAttributes,
}: HotelAttributesGroupProps) => {
  const { state: SFContext } = useContext(AppConfigContext);
  const { dictionary } = SFContext;

  const attributesDictionary = dictionary['Accommodation.Attributes'];

  return (
    <Styled.HotelAttributesGroup withLabel={descriptionMode !== 'tooltip'}>
      {attributes.map((item) => {
        const isHighlighted = includes(selectedAttributes, item);
        const label = hotelAttributesTooltipDescription(item, attributesDictionary);
        const icon = getAttributeIcon(item);

        return (
          <React.Fragment key={item}>
            {icon && (
              <HotelAttribute
                icon={<FontAwesomeIcon icon={icon}/>}
                descriptionMode={descriptionMode}
                isHighlighted={isHighlighted}
                label={label}
              />
            )}
          </React.Fragment>
        );
      })}
    </Styled.HotelAttributesGroup>
  );
};

HotelAttributesGroup.defaultProps = defaultProps;

export { HotelAttributesGroup };
