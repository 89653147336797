import React from 'react';

import { useConditionsSelector } from '@ess/v5-data-provider/components';

import { HotelAttributesGroupProps } from './HotelAttributesGroup';

type PriorityData = {
  regionId: string
}

export const withHighlight = (Component: any, data: PriorityData) => (props: HotelAttributesGroupProps) => {
  const attributesFilter = useConditionsSelector(
    'filter', (state) => state.Accommodation?.Attributes ?? [], undefined, false, [],
  );
  const attributesSearch = useConditionsSelector(
    'search', (state) => state.Accommodation?.Attributes ?? [], undefined, false, [],
  );

  const selectedAttributes = [...attributesFilter, ...attributesSearch];

  return <Component {...props} selectedAttributes={selectedAttributes}/>;
};
